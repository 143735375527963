import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_INTERNAL + '/message';

const initialState = {
  messages: [],
  isLoading: false,
  errormessage: {}
};

// ****************** AsyncThunks *************************
// **** This section makes the asyncronous calls  *********
// **** to the web api's.  The returns will be in *********
// **** extraReducers section of the CreateSlice. *********
// ********************************************************

export const getMessages = createAsyncThunk(
  'message/getMessages',
  async (data, thunkAPI) => {
    console.log('message/getMessages' + JSON.stringify(data));
    if (data.token === '') {
      console.log('Not Logged In');
      return thunkAPI.rejectWithValue('Not Logged In');
    }
    console.log('After message/sendMessage');
    try {
      const url = baseUrl + '/getMessages';
      console.log(data.token);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());

      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      console.log('Error in sendMessage');
      console.log(error);
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const sendMessage = createAsyncThunk(
  'message/sendMessage',
  async (data, thunkAPI) => {
    console.log('message/sendMessage' + JSON.stringify(data));
    if (data.token === '') {
      console.log('Not Logged In');
      return thunkAPI.rejectWithValue('Not Logged In');
    }
    console.log('After message/sendMessage');
    try {
      const url = baseUrl + '/sendMessage';
      console.log(data.token);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());

      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      console.log('Error in sendMessage');
      console.log(error);
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

// ********************* Slice **************************
// *** A slice has two sections, Reducers and        ****
// *** extraReducers.  Reducers allow you to create  ****
// *** dispatable actions. Reducers are exported     ****
// *** below the createSlice section. ExtraReducers  ****
// *** control the return values of the asyncThunks. ****
// *** Each thunk has three potential returns,       ****
// *** pending, fulfilled, and rejected.             ****
// ******************************************************

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},

  // *********
  extraReducers: {
    [sendMessage.pending]: state => {
      state.isLoading = true;
    },
    [sendMessage.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.messages = action.payload;
      /*  if (action.payload.result.isValidToken === true){
            state.resetStatus = "TokenValid"
        }
        else{
            state.resetStatus = "TokenInvalid"
        } */
    },
    [sendMessage.rejected]: state => {
      state.isLoading = false;
    },
    [getMessages.pending]: state => {
      state.isLoading = true;
    },
    [getMessages.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.messages = action.payload;
    },
    [getMessages.rejected]: (state, action) => {
      state.isLoading = false;
      state.errormessage = action.payload;
    }
  }
});

//****** This section exposes the reducers. Place the reducer's in the {}
export const {} = messageSlice.actions;

export default messageSlice.reducer;
