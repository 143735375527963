//this component is the dashboard of the application. It will contain bootstrap cards with other components.
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BookingByHour from '../charts/barBookingByHour';
import RevenueByHour from '../charts/barRevenueByHour';
import { Col, Row, Container, Card } from 'react-bootstrap';
import { getDashboardData } from '../company/companySlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(store => store.user);
  const { dashboardData } = useSelector(store => store.company);

  useEffect(() => {
    console.log('dashboard useEffect');
    let dt = {
      token: currentUser.token
    };
    dispatch(getDashboardData(dt));
  }, []);
  return (
    <div>
      <div className="row mb-3 " >
        <div className="col-2">
          <div className="card bg-dark">
            <div className="card-header border-white bg-dark text-white text-center">
              <h5>Total Members</h5>
            </div>
            <div className="card-body border-white text-white text-center">
              <h1>{dashboardData.totalMembers}</h1>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="card bg-dark">
            <div className="card-header border-white bg-dark text-white text-center">
              <h5>Total Companies</h5>
            </div>
            <div className="card-body border-white text-white text-center">
              <h1>{dashboardData.totalCompanies}</h1>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="card bg-dark">
            <div className="card-header border-white bg-dark text-white text-center">
              <h5>Total Campaigns</h5>
            </div>
            <div className="card-body border-white text-white text-center">
              <h1>{dashboardData.totalCampaigns}</h1>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="card bg-dark">
            <div className="card-header border-white bg-dark text-white text-center">
              <h5>Total Content</h5>
            </div>
            <div className="card-body border-white text-white text-center">
              <h1>{dashboardData.totalMovies}</h1>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="card bg-dark">
            <div className="card-header border-white bg-dark text-white text-center">
              <h5>Total Bookings</h5>
            </div>
            <div className="card-body border-white text-white text-center">
              <h1>{dashboardData.totalBookings}</h1>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="card bg-dark">
            <div className="card-header border-white bg-dark text-white text-center">
              <h5>Total Revenue</h5>
            </div>
            <div className="card-body border-white text-white text-center" style={{display: 'flex', alignItems: 'baseline', justifyContent: 'center'}}>
  <h1>${dashboardData.totalRevenue.toLocaleString()}</h1><h6>USD</h6>
</div>
          </div>
        </div>
        
      </div>
      <div className="row">
        <div className="col-6">
          <div className="card bg-dark">
            <div className="card-header border-white bg-dark text-white">
              <h5>Tickets by Hour</h5>
            </div>
            <div className="card-body border-white">
              <BookingByHour />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card bg-dark">
            <div className="card-header border-white bg-dark text-white">
              <h5>Revenue by Hour (USD)</h5>
            </div>
            <div className="card-body border-white">
              <RevenueByHour />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
