import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table,Button } from "react-bootstrap";
import Header from "../../Header";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { getAllCompanies, updateCurrentCompany } from "./companySlice";
import {getAllPrivileges } from "../user/userSlice";

import { doAddCompany } from '../modal/modalSlice';

const CompanyList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {  currentUser } = useSelector((store) => store.user);
  const {  companies } = useSelector((store) => store.company);


  useEffect(() => {
    // if (loaded === false)
    console.log("token: " );
   
    dispatch(getAllCompanies(currentUser.token));
    dispatch(getAllPrivileges(currentUser.token));
  }, []);
  const handleRowClick = (tcompany) =>{
    dispatch(updateCurrentCompany(tcompany));
    navigate("/campaigns");
    
    }

    const addCompanyClick = () => {
        dispatch(doAddCompany());
        
    }
  return (
    <div className="app-header">
      <Header />
      <Button onClick={() => addCompanyClick()}>Add Provider</Button>
      <Table responsive className='table table-dark table-striped table-hover'>
        <thead>
          <tr>
            <th>Content Provider</th>
            <th>Active Campaigns</th>
            <th>Pending Campaigns</th>
            
          </tr>
        </thead>
        <tbody>
          {companies.map((cmpny) => (
            <tr key={cmpny.id}  onClick={() => handleRowClick(cmpny)}>
              <td> {cmpny.name}</td>
              <td> {cmpny.campaignActive}</td>
              <td> {cmpny.campaignPending}</td>
              
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CompanyList;