import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const baseUrl = process.env.REACT_APP_XCINEX_BE;

const initialState = {
  resetStatus:"",
  isLoading: false,
  errormessage: {}
};

// ****************** AsyncThunks *************************
// **** This section makes the asyncronous calls  *********
// **** to the web api's.  The returns will be in *********
// **** extraReducers section of the CreateSlice. *********
// ********************************************************

export const checkPasswordResetToken = createAsyncThunk(
    "venue/checkPasswordResetToken",
    async (tk, thunkAPI) => {
      if (tk === "") {
        console.log("No Token");
        return thunkAPI.rejectWithValue("No Token");
      }
      try {
        const url = baseUrl + "/users/reset-password/" + tk;
        console.log(url);
        console.log(thunkAPI);
        console.log(thunkAPI.getState());
        //thunkAPI.dispatch(openModal());
        const resp = await axios.get(url);
  
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("something went wrong");
      }
    }
  );


  export const passwordResetToken = createAsyncThunk(
    "venue/passwordResetToken",
    async (data, thunkAPI) => {
      
      try {
        const url = baseUrl + "/users/reset-password/" + data.token;
        console.log(url);
        console.log(thunkAPI);
        console.log(thunkAPI.getState());
        //thunkAPI.dispatch(openModal());
        const resp = await axios.post(url,data.password);
  
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("something went wrong");
      }
    }
  );
  




// ********************* Slice **************************
// *** A slice has two sections, Reducers and        ****
// *** extraReducers.  Reducers allow you to create  ****
// *** dispatable actions. Reducers are exported     ****
// *** below the createSlice section. ExtraReducers  ****
// *** control the return values of the asyncThunks. ****
// *** Each thunk has three potential returns,       ****
// *** pending, fulfilled, and rejected.             ****
// ******************************************************

  const venueSlice = createSlice({
    name: "venue",
    initialState,
    reducers: {
      setResetStatus: (state,action) => {
        state.resetStatus = action.payload;
      },
    },

    // ********* 
    extraReducers: {
      [checkPasswordResetToken.pending]: (state) => {
        state.isLoading = true;
      },
      [checkPasswordResetToken.fulfilled]: (state,action) => {
        // console.log(action);
        state.isLoading = false;
       /*  if (action.payload.result.isValidToken === true){
            state.resetStatus = "TokenValid"
        }
        else{
            state.resetStatus = "TokenInvalid"
        } */
        
        
      },
      [checkPasswordResetToken.rejected]: (state) => {
        state.isLoading = false;
      },



      [passwordResetToken.pending]: (state) => {
        state.isLoading = true;
      },
      [passwordResetToken.fulfilled]: (state,action) => {
        // console.log(action);
        state.isLoading = false;
        
        state.resetStatus = "Password Changed"
        
        
        
      },
      [passwordResetToken.rejected]: (state,action) => {
        state.isLoading = false;
        state.resetStatus = "Error";
        state.errormessage = action;
      },



      
    },
  });
  
  
  //****** This section exposes the reducers. Place the reducer's in the {}
  export const { setResetStatus } = venueSlice.actions;
  
  export default venueSlice.reducer;
  