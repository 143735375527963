import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../App.css";


import { doUserLogin,updateUserLogin } from './userSlice';
import { closeModal } from '../modal/modalSlice';


const Login= () => {
  const { userLogin,currentUser } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  
  

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Before dispatch")
    dispatch(doUserLogin(userLogin));
    dispatch(closeModal());
    console.log(userLogin);
  };
  const handleFormChange = ({ currentTarget: input }) => {
    console.log(input.value);
   
    const login = {
      email: userLogin.email,
      password: userLogin.password,
    } ;
    login[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    console.log(login);
    dispatch(updateUserLogin(login))
    
    
  };
  
    return (
      <div className="app-header">
       
        <form onSubmit={handleFormSubmit}>
          <div>
            {" "}
            Email {""}
            <input
              value={userLogin.email}
              id="email"
              name="email"
              onChange={handleFormChange}
              type="text"
            />
          </div>
          <div>
            {" "}
            Password {""}
            <input
              
              value={userLogin.password}
              id="password"
              name="password"
              onChange={handleFormChange}
              type="password"
            />
          </div>
          <button>Submit</button>
        </form>
      </div>
    );
  
};

export default Login;
