import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import Header from "../../Header";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { getAllUsers,getAllOrgs, updateSelUser,getAllPrivileges,clearUserPrivileges } from "./userSlice";
import { v4 as uuidv4 } from 'uuid';
import { Button } from "react-bootstrap";


const UserList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {  users,currentUser } = useSelector((store) => store.user);

  useEffect(() => {
    // if (loaded === false)
    console.log("token: " );
   
    dispatch(getAllUsers(currentUser.token));
    
    dispatch(getAllPrivileges(currentUser.token));
    dispatch(getAllOrgs(currentUser.token));
  }, []);
  const handleRowClick = (usr) =>{
    dispatch(clearUserPrivileges());
    dispatch(updateSelUser(usr));
    navigate("/editUser");
    }

  // handleNewUser will update the selected user with a new guid in user_id and default the org_id to the current user's org_id.
  const handleNewUser = () => {
    const usr = {
      user_id: uuidv4(),
      org_id: currentUser.org_id,
      email: "",

      first_name: "",
      last_name: "",
      phone: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      zip: "",
      user_status: "active",
      privileges:[]
    };
    dispatch(updateSelUser(usr));
    navigate("/editUser");
  };
  return (
    <div className="app-header">
      <Header />
      <Button onClick={() => handleNewUser()}>Add User</Button>
      <Table responsive className='table table-dark table-striped table-hover'>
        <thead>
          <tr>
            <th>Email</th>
            <th>Org</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone</th>
            <th>City</th>
            <th>State</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {users.map((usr) => (
            <tr key={usr.user_id}  onClick={() => handleRowClick(usr)}>
              <td> {usr.email}</td>
              <td> {usr.org_name}</td>
              <td> {usr.first_name}</td>
              <td> {usr.last_name}</td>
              <td> {usr.phone}</td>
              <td> {usr.city}</td>
              <td> {usr.state}</td>
              <td> {usr.user_status}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UserList;
