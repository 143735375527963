import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import companyReducer from "../features/company/companySlice";
import modalReducer from "../features/modal/modalSlice";
import memberReducer from "../features/member/memberSlice";
import venueReducer from "../features/venue/venueSlice";
import messageReducer from "../features/message/messageSlice";

export * from "../features/user/userSlice";
export * from "../features/company/companySlice";
export * from "../features/modal/modalSlice";
export * from "../features/member/memberSlice";
export * from "../features/venue/venueSlice";
export * from "../features/message/messageSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    company: companyReducer,
    modal: modalReducer,
    members: memberReducer,
    venue: venueReducer,
    message: messageReducer,
  },
});
