import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { useNavigate } from "react-router-dom";
import "../../App.css";

import { closeModal } from "../modal/modalSlice";
import {
  updateCurrentCompany,
  addCompany,
  clearCurrentCompany,
  getAllCompanies,
} from "./companySlice";

const Company = () => {
  const { currentUser } = useSelector((store) => store.user);
  const { currentCompany, isLoading } = useSelector((store) => store.company);
  const dispatch = useDispatch();
  //let navigate = useNavigate();

  useEffect(() => {
    // if (loaded === false)
    console.log("token: ");

    dispatch(clearCurrentCompany());
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Before dispatch");
    let dt = {
      token: currentUser.token,
      company: currentCompany,
    };
    dispatch(addCompany(dt));
    
    
    
    dispatch(getAllCompanies(currentUser.token));
    
    dispatch(closeModal());
    //navigate("/companies")
    //console.log(userLogin);
  };
  const handleFormChange = ({ currentTarget: input }) => {
    console.log(input.value);

    const company = {
      name: currentCompany.name,
    };
    company[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    console.log(company);
    dispatch(updateCurrentCompany(company));
  };

  return (
    <div className="app-header">
      <form onSubmit={handleFormSubmit}>
        <div>
          {" "}
          Name {""}
          <input
            value={currentCompany.name}
            id="name"
            name="name"
            onChange={handleFormChange}
            type="text"
          />
        </div>

        <button>Submit</button>
      </form>
    </div>
  );
};

export default Company;
