import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Header';
import '../../App.css';
import { Container, Col, Row, Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HasPrivilege } from '../user/util';

import {
  resetBookingPosition,
  switchBookingSession,
  getBooking
} from './memberSlice';

const BookingDetail = () => {
  const { currentUser } = useSelector(store => store.user);
  const { isLoading, selBooking } = useSelector(store => store.members);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };
  const handleFormSubmit = e => {
    e.preventDefault();
    console.log('Before dispatch');
    let dt = {
      token: currentUser.token,
      booking: selBooking
    };

    //navigate("/companies")
    //console.log(userLogin);
  };

  const resetBookingPositionClick = () => {
    let data = {
      token: currentUser.token,
      booking: { id: selBooking.id }
    };
    dispatch(resetBookingPosition(data));
  };

  const switchBookingSessionClick = () => {
    let data = {
      token: currentUser.token,
      booking: { id: selBooking.id }
    };
    dispatch(switchBookingSession(data));
  };
  const refreshBookingClick = () => {
    const data = {
      token: currentUser.token,
      booking: { id: selBooking.id }
    };
    dispatch(getBooking(data));
  };
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <div>
      <Header />
      <button
        onClick={handleBackClick}
        style={{
          backgroundColor: 'white',
          border: 'none',
          color: 'black',
          fontSize: '18px',
          cursor: 'pointer'
        }}
      >
        <i className="fas fa-chevron-left"></i> Back
      </button>
      <div className="row container-fluid">
        <div className="card col-xl-3 g-3 m-1 bg-dark text-white">
          <div className="card-header border-white header-background">
            <div className='row'>
              <h5 className='col'>Movie Session</h5>
            
              <svg onClick={() => refreshBookingClick()}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-clockwise col"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
              </svg>
            </div>
          </div>
          <div className="card-body border-white">
            <Table
              responsive
              className="table table-dark table-hover text-white"
            >
              <tr>
                <td>Date</td>
                <td>
                  {new Date(selBooking.purchase_date).toLocaleDateString(
                    'en-US',
                    options
                  )}{' '}
                  {new Date(selBooking.purchase_date).toLocaleTimeString(
                    'en-US'
                  )}
                </td>
              </tr>
              <tr>
                <td>Movie</td>
                <td>{selBooking?.movie?.name || ''}</td>
              </tr>
              <tr>
                <td>Tickets</td>
                <td>{selBooking.ticket_count}</td>
              </tr>
              <tr>
                <td>Total Price</td>
                <td>{selBooking.total_price} USD</td>
              </tr>
              <tr>
                <td>Percent Watched</td>
                <td>{selBooking.percentage_watched}%</td>
              </tr>
              <tr>
                <td>Current Position (sec)</td>
                <td>
                  <div onClick={() => resetBookingPositionClick()}>
                    {selBooking.paused_at}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="Red"
                      class="bi bi-rewind-btn"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.21 5.093A.5.5 0 0 1 8 5.5v1.886l3.21-2.293A.5.5 0 0 1 12 5.5v5a.5.5 0 0 1-.79.407L8 8.614V10.5a.5.5 0 0 1-.79.407l-3.5-2.5a.5.5 0 0 1 0-.814l3.5-2.5Z" />
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4Z" />
                    </svg>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Zip Code</td>
                <td>{selBooking.zipcode}</td>
              </tr>
              <tr>
                <td>Session Active</td>
                <td>
                  {selBooking.end_session === 0 ? (
                    <div onClick={() => switchBookingSessionClick()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="green"
                        class="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </div>
                  ) : (
                    <div onClick={() => switchBookingSessionClick()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="Red"
                        class="bi bi-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      </svg>
                    </div>
                  )}
                </td>
              </tr>
            </Table>
          </div>
        </div>
        <div className="card col-xl-7 g-3 m-1 bg-dark">
          <div className="card-header border-white header-background text-white">
          <div className='row'>
              <h5 className='col'>Booking</h5>
            
              <svg onClick={() => refreshBookingClick()}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-clockwise col"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
              </svg>
            </div>
          </div>
          <div className="card-body border-white">
            <Table
              responsive
              className="table table-dark table-hover text-white"
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Tickets</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {selBooking.booking_payment.map(book => (
                  <tr key={book.id}>
                    <td>
                      {' '}
                      {new Date(book.purchased_date).toLocaleDateString(
                        'en-US',
                        options
                      )}{' '}
                      {new Date(book.purchased_date).toLocaleTimeString(
                        'en-US'
                      )}
                    </td>
                    <td> {book.number_of_ticket}</td>
                    <td> {book.amount} USD</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetail;
