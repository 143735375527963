import React, { useEffect, useState  } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../../App.css";


import { checkPasswordResetToken,passwordResetToken,setResetStatus } from "./venueSlice";


const VenueStagingReset = () => {
  const { resetStatus, isLoading } = useSelector((store) => store.venue);
  const dispatch = useDispatch();
  const  urlParam  = useParams();

  const [password,setPassword] = useState("");
  const [password2,setPassword2] = useState("");
  
  const user = {
    token: "",
        
    password: "",
    password1: "",
  };

  

  const handleFormChange = ({ currentTarget: input }) => {
    console.log(input.value);

    
    user[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    console.log(user);
    //dispatch(updateSelUser(usr));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("submit")
    if (password != password2) {
        dispatch(setResetStatus("Passwords do not match"))
        return
    }
    
    if (password.length < 8) {
      dispatch(setResetStatus("Password must be at least 8 characters long"))
      return
  }
    


    let data = {
        "token":urlParam.token,
        password:{
            "newPassword":password,
            "confirmNewPassword":password2
        }
    }
    dispatch(passwordResetToken(data))

        
  };

  useEffect(() => {
    // This code will run only when the component is mounted
    dispatch(checkPasswordResetToken(urlParam.token))
    
    return () => {
      // This code will run only when the component is unmounted
      console.log("Component unmounted");
    }
  }, []);
  
  return (
    <div style={{ backgroundColor: "white", height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", boxSizing: "border-box", padding: "20px" }}>
  <form onSubmit={handleFormSubmit} style={{ textAlign: "center", border: "1px solid black", borderRadius: "5px", padding: "20px" }}>
    
    <p>{resetStatus}</p>
    <table responsive style={{ margin: "0 auto", textAlign: "left" }}>
      <tbody>
        <tr>
          <td>Password</td>
          <td>
            <input
              value={password}
              id="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              style={{ width: "100%", boxSizing: "border-box", padding: "10px", marginBottom: "10px" }}
            />
          </td>
        </tr>
        <tr>
          <td>Re-enter Password</td>
          <td>
            <input
              value={password2}
              id="password2"
              name="password2"
              onChange={(e) => setPassword2(e.target.value)}
              type="password"
              style={{ width: "100%", boxSizing: "border-box", padding: "10px", marginBottom: "10px" }}
            />
          </td>
        </tr>
      </tbody>
    </table>

    <button style={{ backgroundColor: "orange", padding: "10px", border: "none", color: "white", borderRadius: "5px" }}>Submit</button>
  </form>
</div>

  );
};

export default VenueStagingReset;
