import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from 'react-bootstrap'
import "../../App.css";
import { HasPrivilege, HasPrivilegeNoHook } from "./util";


import { updateUser, updateSelUser, getAllUsers, getAllPrivileges,getUserPrivileges,setUserPrivileges,updateUserPrivileges,sendUserPrivileges } from "./userSlice";
import { doChangePassword } from '../modal/modalSlice';

const EditUserPrivilege = () => {
  const { selUser, currentUser, selPrivileges,privileges,userPrivileges } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  let canSavePrivileges = false;
  const pr = [];
  
  useEffect(() => {
    //dispatch(getAllPrivileges(currentUser.token));
  
    if (selUser.user_id === currentUser.user_id){
      canSavePrivileges = false;
    }else{
      canSavePrivileges = true;
    }
    
    console.log("before foreach");
    privileges.forEach(p => {
        
        const tp = {
            privilege: p.privilege,
            description: p.description,
            isSelected: selUser.privileges.some(u=>u.privilege === p.privilege),
        }
        const hasP = HasPrivilegeNoHook(p.privilege,currentUser.privileges);
        if (hasP) {
          pr.push(tp);
        }
        
        
    });
    console.log(pr);
    dispatch(setUserPrivileges(pr));
  },[]);
  const handleFormSubmit = () => {
    let data = {
      privilegeData: {
        user: selUser,
        privileges: userPrivileges,
      },
      token: currentUser.token,
    }
    dispatch(sendUserPrivileges(data));

  }
  const handleFormChange = (priv) => {
    console.log(priv.privilege);
    dispatch(updateUserPrivileges(priv));

    //usr[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    //console.log(usr);
   
  };
  
  
  return (
    <div className="app-header">
    
     
        <table responsive className='table table-dark'>
          <tbody>
          {userPrivileges.map(pp => (
            <tr  key={pp.privilege}>
             
              <td>
              <Form.Check 
                type={'checkbox'}
                id='isSelected'
                defaultChecked={pp.isSelected}
                onClick={() => handleFormChange(pp)}
        
      />
              </td>
               <td>{pp.description}</td>
            </tr>
             ))}
          </tbody>
        </table>
        {selUser.user_id != currentUser.user_id ? 
        <button onClick={() => handleFormSubmit()}>Save</button>
        : ""}
     
     
    </div>
  );
};

export default EditUserPrivilege;
