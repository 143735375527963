import React from "react";
import { BrowserRouter, BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./features/home/Home";


import Login from "./features/user/Login";
import Logout from "./features/user/logout";
import UserList from "./features/user/userList";
import EditUser from "./features/user/editUser";
import RegisterUser from "./features/user/registerUser";


import CompanyList from "./features/company/companyList";
import Campaigns from "./features/company/campaignList";
import CampaignReport from "./features/company/campaignReport";
import CampaignBooking from "./features/company/campaignBooking";
import AdminUser from "./features/company/adminUser";


import MemberList from "./features/member/memberList";
import MemberDetail from "./features/member/memberDetail";
import BookingDetail from "./features/member/bookingDetail";

import MessageInput from "./features/message/messageInput";

//venue
import VenueStagingReset from "./features/venue/stagingPasswordReset";

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route  path={"/venue/stagingPasswordReset/:token"} element={<VenueStagingReset />} />
          <Route path={"/"} element={<Home />} />

          {/************* Internal Users  *********/}
          <Route exact path={"/login"} element={<Login />} /> 
          <Route exact path={"/users"} element={<UserList />} />
          <Route exact path={"/editUser"} element={<EditUser />} />
          <Route exact path={"/registerUser"} element={<RegisterUser />} />
          <Route exact path={"/logout"} element={<Logout />} />

          {/************* Companies  *********/}
          <Route exact path={"/companies"} element={<CompanyList />} />
          <Route exact path={"/campaigns"} element={<Campaigns />} />
          <Route exact path={"/campaignReport"} element={<CampaignReport />} />
          <Route exact path={"/campaignBooking"} element={<CampaignBooking />} />
          <Route exact path={"/adminUser"} element={<AdminUser />} />
          {/************* Members  *********/}
          <Route exact path={"/members"} element={<MemberList />} />
          <Route exact path={"/memberDetail"} element={<MemberDetail />} />
          <Route exact path={"/bookingDetail"} element={<BookingDetail />} />

          {/************* Message  *********/}
          <Route exact path={"/message"} element={<MessageInput />} />


        </Routes>
    </BrowserRouter>
  );
}

export default App;
