import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Card } from 'react-bootstrap';
import Header from '../../Header';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import {
  getCampaignsByCompany,
  updateCurrentCompany,
  getBookingHistoryByCampaign
} from './companySlice';
import { getAdminMembers, updateSelMember } from '../member/memberSlice';
import { getAllPrivileges } from '../user/userSlice';

import { doAddCompany } from '../modal/modalSlice';

const CampaignList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { currentUser } = useSelector(store => store.user);
  const { campaigns, currentCompany, bookingHistory } = useSelector(
    store => store.company
  );
  const { members } = useSelector(store => store.members);
  const [localcampaigns, setLocalCampaigns] = useState(campaigns);
  const [searchQuery, setSearchQuery] = useState('');
  let iosTotalSum = localcampaigns.reduce(
    (sum, c) => sum + (c.ios_total || 0),
    0
  );
  let stripeTotalSum = localcampaigns.reduce(
    (sum, c) => sum + (c.stripe_total || 0),
    0
  );
  let paystackTotalSum = localcampaigns.reduce(
    (sum, c) => sum + (c.paystack_total || 0),
    0
  );

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    // if (loaded === false)
    console.log('token: ');
    let dt = {
      company: currentCompany,
      token: currentUser.token
    };
    dispatch(getCampaignsByCompany(dt));
    const data = {
      company_id: currentCompany.id,
      token: currentUser.token
    };
    console.log('data: ' + JSON.stringify(data));
    dispatch(getAdminMembers(data));
  }, []);
  useEffect(() => {
    setLocalCampaigns(campaigns);
  }, [campaigns]);

  const sortLocalCampaigns = columnName => {
    const newLocalcampaigns = [...localcampaigns];
    newLocalcampaigns.sort((a, b) => {
      const valueA = a[columnName] ? a[columnName].toLowerCase() : '';
      const valueB = b[columnName] ? b[columnName].toLowerCase() : '';
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    setLocalCampaigns(newLocalcampaigns);
  };
  const sortLocalCampaignsNumber = columnName => {
    const newLocalcampaigns = [...localcampaigns];
    newLocalcampaigns.sort((a, b) => {
      const valueA = a[columnName] ? a[columnName] : 0;
      const valueB = b[columnName] ? b[columnName] : 0;
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    setLocalCampaigns(newLocalcampaigns);
  };

  const sortLocalCampaignsDate = columnName => {
    const newLocalcampaigns = [...localcampaigns];
    newLocalcampaigns.sort((a, b) => {
      const valueA = a[columnName] ? Date.parse(a[columnName]) : 0;
      const valueB = b[columnName] ? Date.parse(b[columnName]) : 0;
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    setLocalCampaigns(newLocalcampaigns);
  };

  const handleRowClick = mbr => {
    dispatch(updateSelMember(mbr));
    navigate('/adminUser');
  };

  const selCampaignClick = tcampaign => {
    const data = {
      data: { movie_id: tcampaign.movie_id },
      token: currentUser.token
    };
    dispatch(getBookingHistoryByCampaign(data));
    navigate('/campaignBooking');
  };
  const handleSearch = e => {
    e.preventDefault();
    filterCampaigns(searchQuery);
  };
  const filterCampaigns = query => {
    const filteredCampaigns = campaigns.filter(campaign => {
      return campaign.title?.toLowerCase().includes(query.toLowerCase());
    });
    setLocalCampaigns(filteredCampaigns);
  };
  return (
    <div className="app-header">
      <Header />
      <button
        onClick={handleBackClick}
        style={{
          backgroundColor: 'white',
          border: 'none',
          color: 'black',
          fontSize: '18px',
          cursor: 'pointer'
        }}
      >
        <i className="fas fa-chevron-left"></i> Back
      </button>
      <div className="row container-fluid">
  
  <div className="white-text col-4">
    <Card className="bg-dark text-white">
      <Card.Header>Admin Users</Card.Header>
      <Card.Body>
        <Table
          responsive
          className="table table-dark table-striped table-hover"
        >
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {members.map(member => (
              <tr key={member.id} onClick={() => handleRowClick(member)}>
                <td>{member.first_name}</td>
                <td>{member.last_name}</td>
                <td>{member.email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  </div>
</div>
      <form onSubmit={handleSearch}>
        <input
          type="text"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
        <button type="submit">Search</button>
      </form>
      <div className="text-white">
        IOS: {iosTotalSum.toFixed(2)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Stripe:{' '}
        {stripeTotalSum.toFixed(2)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Paystack:{' '}
        {paystackTotalSum.toFixed(2)}
      </div>
      <Table responsive className="table table-dark table-striped table-hover">
        <thead>
          <tr>
            <th onClick={() => sortLocalCampaigns('title')}>Movie Title</th>
            <th onClick={() => sortLocalCampaigns('status')}>
              Campaign Status
            </th>
            <th onClick={() => sortLocalCampaigns('release_template_name')}>
              Release Template
            </th>
            <th onClick={() => sortLocalCampaignsDate('release_date')}>
              Release Date
            </th>
            <th onClick={() => sortLocalCampaignsDate('ended_at')}>End Date</th>
            <th onClick={() => sortLocalCampaignsNumber('total_price')}>
              Gross Revenue
            </th>
            <th onClick={() => sortLocalCampaignsNumber('ticket_count')}>
              Tickets Sold
            </th>
            <th onClick={() => sortLocalCampaignsNumber('average_price')}>
              Average Price
            </th>
            <th>IOS</th>
            <th>Paystack</th>
            <th>Stripe</th>
            <th>VAT %</th>
            <th>VAT</th>
          </tr>
        </thead>
        <tbody>
          {localcampaigns.map(c => (
            <tr key={c.id} onClick={() => selCampaignClick(c)}>
              <td> {c.title}</td>
              <td> {c.status}</td>
              <td> {c.release_template_name}</td>
              <td>{c.release_date}</td>
              <td>{c.ended_at}</td>
              <td>{c.total_price.toFixed(2)} </td>
              <td>{c.ticket_count}</td>
              <td>{c.average_price ? c.average_price.toFixed(2) : ''} </td>
              <td>{c.ios_total ? c.ios_total.toFixed(2) : 'N/A'}</td>
              <td>{c.paystack_total ? c.paystack_total.toFixed(2) : ''}</td>
              <td>{c.stripe_total ? c.stripe_total.toFixed(2) : ''}</td>
              <td>{c.vat ? c.vat.toFixed(2) : ''}%</td>
              <td>
                {c.total_price && c.vat
                  ? Number(c.total_price * (c.vat / 100)).toFixed(2)
                  : 'N/A'}{' '}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CampaignList;
