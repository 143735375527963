import React from "react";

import {  useSelector, useDispatch } from "react-redux";
import { Navbar, Container,  Nav, } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { doLogin,doRegisterUser } from './features/modal/modalSlice';

import { HasPrivilege } from './features/user/util';
import logo from "./assets/xcinexwhite.png"

export default function Header() {
  
  const { isLoggedIn } = useSelector((store) => store.user.currentUser);
  const dispatch = useDispatch();
  const displayUser = HasPrivilege("viewuser");
  const displayCompany = HasPrivilege("companylist");
  const displayMember = HasPrivilege("memberlist");
  const displayHeader = (loggedIn,displayUser) => {
    
    return (
      <Navbar  className="navbar-dark" expand="lg">
        <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
          <img
              src={logo}
              alt="Xcinex"
              style={{ maxWidth: "150px", height: "auto" }}
            />
          </Navbar.Brand>
        </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {loggedIn ? (
              <Nav className="me-auto">
                <LinkContainer to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
               
                {displayUser ? (
                <LinkContainer to="/users">
                  <Nav.Link>Users</Nav.Link>
                </LinkContainer>
                
                ) : ("")}
                {displayCompany ? (
                <LinkContainer to="/companies">
                  <Nav.Link>Providers</Nav.Link>
                </LinkContainer>
                 ) : ("")}

                {displayMember ? (
                <LinkContainer to="/members">
                  <Nav.Link>Members</Nav.Link>
                </LinkContainer>
                 ) : ("")}
                 <LinkContainer to="/campaignReport">
                  <Nav.Link>Report</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/message">
                  <Nav.Link>Messages</Nav.Link>
                </LinkContainer>
              </Nav>
            ) : (
              <Nav className="me-auto"></Nav>
            )}
            <Nav pullRight>
              {loggedIn === false ? (
                <Nav className="me-auto">
               
                  <Nav.Link onClick={ () => {dispatch(doLogin());}}>Login</Nav.Link>
            
                
                  <Nav.Link onClick={ () => {dispatch(doRegisterUser());}}>Register</Nav.Link>
                
                </Nav>
              ) : (
                <LinkContainer to="/logout">
                  <Nav.Link>Logout</Nav.Link>
                </LinkContainer>
                
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };
  
  return <header>{displayHeader(isLoggedIn,displayUser)}</header>;
}
