import React, { useEffect,useState  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Form } from 'react-bootstrap';
import Header from '../../Header';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import { getMembers,updateSelMember } from './memberSlice';
import { getAllPrivileges } from '../user/userSlice';

const MemberList = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { currentUser } = useSelector(store => store.user);
  const { members } = useSelector(store => store.members);
  const [localMembers, setLocalMembers] = useState(members);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    filterMembers(searchQuery);
  };
  useEffect(() => {
    // if (loaded === false)
    console.log('token: ');

    dispatch(getMembers(currentUser.token));
    dispatch(getAllPrivileges(currentUser.token));
  }, []);
  const handleRowClick = mbr => {
    dispatch(updateSelMember(mbr));
    navigate('/memberDetail');
  };
  useEffect(() => {
    setLocalMembers(members);
  }, [members]);

  const [sortOrder, setSortOrder] = useState({
    column: null,
    direction: 'asc',
  });
  const sortLocalMembers = (columnName) => {
    const newLocalMembers = [...localMembers];
    newLocalMembers.sort((a, b) => {
      const valueA = a[columnName] ? a[columnName].toLowerCase() : "";
    const valueB = b[columnName] ? b[columnName].toLowerCase() : "";
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
    setLocalMembers(newLocalMembers);
  };
  const options = {
    
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  const filterMembers = (query) => {
    const filteredMembers = members.filter((member) => {
      return (
        member.email?.toLowerCase().includes(query.toLowerCase()) ||
        member.first_name?.toLowerCase().includes(query.toLowerCase()) ||
        member.last_name?.toLowerCase().includes(query.toLowerCase()) ||
        member.account_type?.toLowerCase().includes(query.toLowerCase()) ||
        member.booking?.length?.toString().includes(query) ||
        member.email_verified?.toLowerCase().includes(query.toLowerCase()) ||
        member.status?.toLowerCase().includes(query.toLowerCase()) ||
        member.city?.toLowerCase().includes(query.toLowerCase()) ||
        member.country?.toLowerCase().includes(query.toLowerCase()) ||
        new Date(member.created_at)?.toLocaleDateString("en-US").includes(query) ||
        new Date(member.created_at)?.toLocaleTimeString("en-US").includes(query)
      );
    });
    setLocalMembers(filteredMembers);
  };
  
  
  
  
  return (
    <div className="app-header">
      <Header />
      <form onSubmit={handleSearch}>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button type="submit">Search</button>
      </form>
      <Table responsive className='table table-dark table-striped table-hover'>
        <thead>
          <tr>
            <th onClick={() => sortLocalMembers('email')}>Email</th>
            <th onClick={() => sortLocalMembers('first_name')}>First Name</th>
            <th onClick={() => sortLocalMembers('last_name')}>Last Name</th>
            <th onClick={() => sortLocalMembers('account_type')}>Account Type</th>
            <th onClick={() => sortLocalMembers('booking.length')}>Movies Watched</th>
            <th onClick={() => sortLocalMembers('email_verified')}>Email Verified</th>
            <th onClick={() => sortLocalMembers('status')}>Status</th>
            <th onClick={() => sortLocalMembers('city')}>City</th>
            <th onClick={() => sortLocalMembers('country')}>Country</th>
            <th onClick={() => sortLocalMembers('created_at')}>Registered</th>
          </tr>
        </thead>
        <tbody>
          {localMembers.map(mbr => (
            <tr key={mbr.id} onClick={() => handleRowClick(mbr)}>
              <td> {mbr.email}</td>
              <td> {mbr.first_name}</td>
              <td> {mbr.last_name}</td>
              <td> {mbr.account_type}</td>
              <td>{mbr.booking.length}</td>
              <td>
            {mbr.email_verified === "true" ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0CE887" class="bi bi-circle-fill" viewBox="0 0 16 16">
              <circle cx="8" cy="8" r="8"/>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#E83517" class="bi bi-circle-fill" viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8"/>
                </svg>
            )}
            
            
            
            </td>
              <td>
                 {mbr.status === "active" ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0CE887" class="bi bi-circle-fill" viewBox="0 0 16 16">
              <circle cx="8" cy="8" r="8"/>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#E83517" class="bi bi-circle-fill" viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8"/>
                </svg>
            )}
                 </td>
                 <td> {mbr.city}</td>
                 <td> {mbr.country}</td>
                 <td> {new Date(mbr.created_at).toLocaleDateString("en-US",options)} { new Date(mbr.created_at).toLocaleTimeString("en-US")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MemberList;
