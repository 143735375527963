import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage, getMessages } from './messageSlice';
import { Table } from 'react-bootstrap';
import Header from '../../Header';

const MessageInput = () => {
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [country, setCountry] = useState(''); 
    const { currentUser } = useSelector(store => store.user);
    const { messages } = useSelector(store => store.message);
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };
    const handleInputChangetitle = (e) => {
        setTitle(e.target.value);
    };
    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };
    const handleSendMessage = () => {
        console.log('handleSendMessage');
        const newmessage = {
            message: message,
            title: title,
            country: country, 
            token: currentUser.token
        };
        console.log(newmessage);
        dispatch(sendMessage(newmessage));
        setMessage('');
        setTitle('');
       
    };
    useEffect(() => {
        console.log('message useEffect');
        let dt = {
            token: currentUser.token
        };
        dispatch(getMessages(dt));
    }, [dispatch, currentUser.token]);

    return (
        <div>
            <Header />
            <div className='col-4'>
                <table className="table table-dark table-hover text-white">
                    <tbody>
                        <tr>
                            <td>Title:</td>
                            <td><input type="text" value={title} onChange={handleInputChangetitle} /></td>
                        </tr>
                        <tr>
                            <td>Message:</td>
                            <td><input type="text" value={message} onChange={handleInputChange} /></td>
                        </tr>
                        <tr>
                            <td>Country:</td>
                            <td>
                                <select value={country} onChange={handleCountryChange}>
                                    <option value="">All</option>
                                    <option value="US">US</option>
                                    <option value="TR">TR</option>
                                    <option value="AE">AE</option>
                                    <option value="SA">SA</option>
                                    <option value="IN">IN</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><button onClick={handleSendMessage}>Send Message</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Table striped bordered hover className="table table-dark table-hover text-white">
                <thead>
                    <tr>
                        <th>Message</th>
                        <th>Title</th>
                        <th>Country</th>
                        <th>Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                    {messages.map((message) => (
                        <tr key={message.message_id}>
                            <td>{message.message}</td>
                            <td>{message.title}</td>
                            <td>{message.country}</td>
                            <td>{new Date(message.timestamp * 1000).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default MessageInput;
