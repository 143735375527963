import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const SelectCompanyModal = ({
  show,
  setShow,
  onClose,
  companies,
  onSelect
}) => {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCompanySelect = company => {
    onSelect(company);
    handleClose();
  };

  return (
    <>
      

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select a Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <table style={{borderSpacing: "0 15px"}}>
                <thead>
                    <tr>
                        <th style={{width: "150px", textAlign: "center"}}>ID</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {companies.map(company => (
                        <tr
                            key={company.id}
                            onClick={() => handleCompanySelect(company)}
                        >
                            <td style={{textAlign: "center"}}>{company.id}</td>
                            <td>{company.name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectCompanyModal;
