import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Header';
import '../../App.css';
import { Container, Col, Row, Table,Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HasPrivilege } from '../user/util';




const CampaignBooking = () => {
  const { currentUser } = useSelector(store => store.user);
  const { selCampaign,bookingHistory } = useSelector(store => store.company);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };
  

  return (
    <div>
      <Header />
      <div className="row container-fluid">
        <div className="card col-xl-7 g-3 m-1 bg-dark">
        <div className="card-header border-white header-background text-white">
            <h5>Booking</h5>
          </div>
          <div className="card-body border-white">
          <Table responsive className='table table-dark table-hover text-white'>
            <thead>
              <tr>
                
                <th>Dates</th>
                <th>Email</th>
                <th>First</th>
                <th>Last</th>
                <th>Payment ID</th>
                <th>Provider</th>
                <th>Tickets</th>
                <th>Total</th>
                
              </tr>
            </thead>
            <tbody>
              {bookingHistory.map(book => (
                <tr key={book.id}  >
                  
                  <td>
                    {' '}
                    {new Date(book.purchased_date).toLocaleDateString(
                      'en-US',
                      options
                    )}{' '}
                    {new Date(book.purchased_date).toLocaleTimeString('en-US')}
                  </td>
                  <td>{book.email}</td>
                  <td>{book.memberProfile.first_name}</td>
                  <td>{book.memberProfile.last_name}</td>
                  <td>{book.payment_id}</td>
                  <td>{book.paymentType}</td>
                  <td> {book.number_of_ticket}</td>
                  <td> {book.amount ? book.amount.toFixed(2) : ''} </td>
                  
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignBooking;
