import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "react-bootstrap";
import Header from "../../Header";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { getCampaignsByCompany, updateCurrentCompany,getCampaignReport,setSelCampaign } from "./companySlice";
import { getAllPrivileges } from "../user/userSlice";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Margin } from "@mui/icons-material";

const CampaignReport = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { currentUser } = useSelector((store) => store.user);
  const { campaigns, currentCompany,campaignReport } = useSelector((store) => store.company);
  const [localcampaigns, setLocalCampaigns] = useState(campaigns);
 
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());

  const totalPriceSum = campaignReport.reduce((accumulator, customer) => {
    return accumulator + customer.grossRevenue;
  }, 0);

  const totalTicketSum = campaignReport.reduce((accumulator, customer) => {
    return accumulator + customer.totalTickets;
  }, 0);

  const totalVatSum = campaignReport.reduce((accumulator, customer) => {
    return accumulator + customer.totalVat;
  }, 0);
  useEffect(() => {
    console.log("token: ");
    let dt = {
      company: currentCompany,
      token: currentUser.token,
      startDate: startDate,
      endDate: endDate
    };
    dispatch(getCampaignReport(dt));
  }, []);

  useEffect(() => {
    setLocalCampaigns(campaigns);
  }, [campaigns]);

  
  const CampaignClick = (tcampaign) => {
    dispatch(setSelCampaign(tcampaign));
    navigate("/campaignBooking");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let dt = {
      company: currentCompany,
      token: currentUser.token,
      startDate: startDate,
      endDate: endDate
    };
    dispatch(getCampaignReport(dt));
  };

  

  return (
    <div className="app-header">
      <Header /> 
      
      <form onSubmit={handleSearch}>
        <div className="row">
        <div className="col-2"> <label htmlFor="start-date" className="text-white">Start Date:</label><DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /></div>
        <div className="col-2"><label htmlFor="start-date" className="text-white">End Date:</label><DatePicker selected={endDate} onChange={(date) => setEndDate(date)} /></div>
        <div className="col-2"><button type="submit">Search</button></div>
        </div>
      </form>
     
      <div className="row"  style={{ marginTop: '40px' }}>
    <div className="col-4"> <h3 className="text-white">Total Revenue: {totalPriceSum ? totalPriceSum.toFixed(2) : null}</h3></div>
    <div className="col-4"><h3 className="text-white">Total Tickets Sold: {totalTicketSum}</h3></div>
    <div className="col-4"><h3 className="text-white">Total Vat: ${totalVatSum ? totalVatSum.toFixed(2) : null} USD</h3></div>
</div>
      {campaignReport.map((comp) => (
        <>
        <div className="row"  style={{ marginTop: '40px' }}>
    <div className="col-2"> <h3 className="text-white">{comp.name}</h3></div>
    <div className="col-2"><h6 className="text-white">Total Revenue: {comp.grossRevenue ? comp.grossRevenue.toFixed(2) : null}</h6></div>
    <div className="col-2"><h6 className="text-white">Total Tickets Sold: {comp.totalTickets}</h6></div>
    <div className="col-2"><h6 className="text-white">Total Vat: {comp.totalVat ? comp.totalVat.toFixed(2) : null}</h6></div>
</div>
       
      <Table responsive className='table table-dark table-striped table-hover'>
        <thead>
          <tr>
            
            <th >Movie Title</th>
            <th >Campaign Status</th>
            <th >MPAA Rating</th>
            <th >Release Date</th>
            <th >End Date</th>
            <th>Gross Revenue</th>
            <th>Tickets Sold</th>
            <th>Average Price</th>
            <th>VAT %</th>
            <th>VAT</th>
            
          </tr>
        </thead>
        <tbody>
          {comp.campaigns.map((c) => (
            <tr key={c.id}  onClick={() => CampaignClick(c)}>
              
              <td> {c.title}</td>
<td> {c.status}</td>
<td> {c.mpaa_rating}</td>
<td>{c.release_date}</td>
<td>{c.ended_at}</td>
<td>{c.total_price ? c.total_price.toFixed(2) : null} </td>
<td>{c.ticket_count}</td>
<td>{c.average_price ? c.average_price.toFixed(2) : null} </td>
<td>{c.vat ? c.vat.toFixed(2) : null}%</td>
<td>{c.total_price && c.vat ? Number(c.total_price * (c.vat/100)).toFixed(2) : null} </td>
              
            </tr>
          ))}
        </tbody>
      </Table>
      </>))}
    </div>
  );
};

export default CampaignReport;
