import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactEChart from 'echarts-for-react';
import { getPurchaseByHour } from '../company/companySlice';

const BarRevenueByHour = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((store) => store.user);
  const { currentCompany, purchaseCountbyHour } = useSelector(
    (store) => store.company
  );

  useEffect(() => {
    //create a variable to hold the token, startDate that is 7 days ago and endDate that is today
    console.log("barBookingByHour useEffect")
    let dt = {
      token: currentUser.token,
      startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
    };
    dispatch(getPurchaseByHour(dt));
  }, []);

  const getOption = () => {
    return {
      title: {
        text: '',
      },
      tooltip: {
        trigger: 'axis',
        position: 'bottom', // set the tooltip position to top
        offset: [0, 10], // set the vertical offset to 10 pixels
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        borderColor: '#c8e2f7',
        textStyle: {
          color: '#000'
      }
      },
      legend: {
        data: ['Purchase'],
        textStyle: {
            color: 'white'
          }
      },
      xAxis: {
        data: purchaseCountbyHour.map((item) => item.hour),
        
      },
      yAxis: {},
      series: [
        {
          name: 'Streams',
          type: 'bar',
          data: purchaseCountbyHour.map((item) => item.revenue),
        },
      ],
    };
  };

  

  return (
    <div>
      <ReactEChart option={getOption()} />
    </div>
  );
};

export default BarRevenueByHour;
