import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Header';
import '../../App.css';
import { Container, Col, Row, Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HasPrivilege } from '../user/util';
import SelectCompanyModal from '../company/selectCompany.modal';

import {
  updateUpdateMemberPersonal,
  updateSelMember,
  saveMemberPersonal,
  setVerifyEmail,
  getBooking,
  toggleStatus
} from './memberSlice';
import {
  addContentAdmin,
  removeContentAdmin,
  getAllCompanies
} from '../company/companySlice';

const MemberDetail = () => {
  const [show, setShow] = useState(false);

  const { currentUser } = useSelector(store => store.user);
  const { isLoading, selMember } = useSelector(store => store.members);
  const { companies } = useSelector(store => store.company);

  const handleShow = () => setShow(true);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = company => {
    // Handle the selected company here
    setShowModal(false);
  };

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };
  const handleFormSubmit = e => {
    e.preventDefault();
    console.log('Before dispatch');
    let dt = {
      token: currentUser.token,
      member: {
        user_id: selMember.id,
        email: selMember.email,
        first_name: selMember.first_name,
        last_name: selMember.last_name,
        phone: selMember.phone,
        physical_address: selMember.physical_address,
        city: selMember.city,
        state: selMember.state,
        zipcode: selMember.zipcode,
        country: selMember.country
      }
    };
    dispatch(saveMemberPersonal(dt));
  };
  useEffect(() => {
    dispatch(getAllCompanies(currentUser.token));
  }, []);
  useEffect(() => {
    console.log(selMember);
  }, [selMember]);
  const handleFormChange = ({ currentTarget: input }) => {
    console.log(input.value);

    const member = {
      email: selMember.email,
      first_name: selMember.first_name,
      last_name: selMember.last_name,
      phone: selMember.phone,
      physical_address: selMember.physical_address,
      city: selMember.city,
      state: selMember.state,
      zipcode: selMember.zipcode,
      country: selMember.country
    };
    const handleCompanySelect = company => {
      // Handle the selected company here
    };

    member[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    console.log(member);
    dispatch(updateUpdateMemberPersonal(member));
  };

  const addEmailVerifyClick = usr => {
    let data = {
      token: currentUser.token,
      member: { user_id: selMember.id }
    };
    dispatch(setVerifyEmail(data));
  };
  const toggleStatusClick = usr => {
    let data = {
      token: currentUser.token,
      member: { user_id: selMember.id }
    };
    dispatch(toggleStatus(data));
  };
  const handleRowClick = book => {
    const data = {
      token: currentUser.token,
      booking: { id: book.id }
    };
    dispatch(getBooking(data));
    navigate('/bookingDetail');
  };

  const handleAddContentAdmin = () => {
    // Open dialog box and select a company
    handleShow();
  };

  const handleRemoveContentAdmin = () => {
    // Dispatch removeContentAdmin with email and token
    dispatch(removeContentAdmin({ email: selMember.email, token: currentUser.token }));
    
  };

  const handleCompanySelect = company => {
    console.log(company);
    // Dispatch addContentAdmin with email, company, and token
    dispatch(addContentAdmin({ email: selMember.email, company, token: currentUser.token }));
    let temp = {...selMember};
    temp.company_id = company.id;
    temp.account_type = 'content-admin';
    
    dispatch(updateSelMember(temp));
  }
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div>
      <Header />
      <button
        onClick={handleBackClick}
        style={{
          backgroundColor: 'white',
          border: 'none',
          color: 'black',
          fontSize: '18px',
          cursor: 'pointer'
        }}
      >
        <i className="fas fa-chevron-left"></i> Back
      </button>
      <SelectCompanyModal
        show={showModal}
        setShow={setShowModal}
        onClose={handleCloseModal}
        companies={companies}
        onSelect={handleCompanySelect}
      />
      <div className="row container-fluid">
        <div className="card col-xl-3 g-3 m-1 bg-dark text-white">
          <div className="card-header border-white header-background">
            <h5>Personal</h5>
          </div>
          <div className="card-body border-white">
            <form onSubmit={handleFormSubmit} className="border-white">
              <Table
                responsive
                className="table table-dark table-hover text-white"
              >
                <tr>
                  <td>Email</td>
                  <td>
                    <input
                      value={selMember.email}
                      id="email"
                      name="email"
                      onChange={handleFormChange}
                      type="text"
                      className="input-background text-white"
                    />
                  </td>
                </tr>
                <tr>
                  <td>First Name</td>
                  <td>
                    <input
                      className="input-background text-white"
                      value={selMember.first_name}
                      id="first_name"
                      name="first_name"
                      onChange={handleFormChange}
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>
                    <input
                      className="input-background text-white"
                      value={selMember.last_name}
                      id="last_name"
                      name="last_name"
                      onChange={handleFormChange}
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>
                    <input
                      className="input-background text-white"
                      value={selMember.phone}
                      id="phone"
                      name="phone"
                      onChange={handleFormChange}
                      type="text"
                    />
                  </td>
                </tr>

                <tr>
                  <td>Address</td>
                  <td>
                    <input
                      className="input-background text-white"
                      value={selMember.physical_address}
                      id="physical_address"
                      name="physical_address"
                      onChange={handleFormChange}
                      type="text"
                    />
                  </td>
                </tr>

                <tr>
                  <td>City</td>
                  <td>
                    <input
                      className="input-background text-white"
                      value={selMember.city}
                      id="city"
                      name="city"
                      onChange={handleFormChange}
                      type="text"
                    />
                  </td>
                </tr>

                <tr>
                  <td>State</td>
                  <td>
                    <input
                      className="input-background text-white"
                      value={selMember.state}
                      id="state"
                      name="state"
                      onChange={handleFormChange}
                      type="text"
                    />
                  </td>
                </tr>

                <tr>
                  <td>Zip</td>
                  <td>
                    <input
                      className="input-background text-white"
                      value={selMember.zipcode}
                      id="zipcode"
                      name="zipcode"
                      onChange={handleFormChange}
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>
                    <input
                      className="input-background text-white"
                      value={selMember.country}
                      id="country"
                      name="country"
                      onChange={handleFormChange}
                      type="text"
                    />
                  </td>
                </tr>
                {selMember.account_type === 'content-admin' && (
                  <tr>
                    <td>Company</td>
                    <td >
                       <input
                      className="input-background text-white"
                      value={companies.find(company => company.id === selMember.company_id)?.name}
                      id="companyname"
                      name="companyname"
                      
                      type="text"
                      
                    />

                    </td>
                  </tr>
                )}


                <tr>
                  <td>Account Type</td>
                  <td>
                    <input
                      className="input-background text-white"
                      value={selMember.account_type}
                      id="account_type"
                      name="account_type"
                      onChange={handleFormChange}
                      type="text"
                    />
                    <button
                      className="input-background text-white"
                      onClick={() => {
                        if (selMember.account_type === 'member') {
                          setShowModal(true);
                        } else if (selMember.account_type === 'content-admin') {
                          handleRemoveContentAdmin();
                        }
                      }}
                    >
                      {selMember.account_type === 'member'
                        ? 'Add Content Admin'
                        : 'Remove Content Admin'}
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Email Verified</td>
                  <td>
                    <div>
                      {selMember.email_verified === 'true' ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#0CE887"
                          class="bi bi-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                      ) : (
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#E83517"
                            class="bi bi-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <circle cx="8" cy="8" r="8" />
                          </svg>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Button onClick={() => addEmailVerifyClick()}>
                            Verify
                          </Button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <div>
                      {selMember.status === 'active' ? (
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#0CE887"
                            class="bi bi-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <circle cx="8" cy="8" r="8" />
                          </svg>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Button onClick={() => toggleStatusClick()}>
                            Set Inactive
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#E83517"
                            class="bi bi-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <circle cx="8" cy="8" r="8" />
                          </svg>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Button onClick={() => toggleStatusClick()}>
                            Set Active
                          </Button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </Table>

              <button>Save</button>
            </form>
          </div>
        </div>
        <div className="card col-xl-7 g-3 m-1 bg-dark">
          <div className="card-header border-white header-background text-white">
            <h5>Booking</h5>
          </div>
          <div className="card-body border-white">
            <Table
              responsive
              className="table table-dark table-hover text-white"
            >
              <thead>
                <tr>
                  <th>Movie Id</th>
                  <th>Date</th>
                  <th>Tickets</th>
                  <th>Total</th>
                  <th>Percent Watched</th>
                  <th>Position</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {selMember.booking.map(book => (
                  <tr key={book.id} onClick={() => handleRowClick(book)}>
                    <td> {book.movie_name}</td>
                    <td>
                      {' '}
                      {new Date(book.purchase_date).toLocaleDateString(
                        'en-US',
                        options
                      )}{' '}
                      {new Date(book.purchase_date).toLocaleTimeString('en-US')}
                    </td>
                    <td> {book.ticket_count}</td>
                    <td> {book.total_price}</td>
                    <td>{book.percentage_watched}</td>
                    <td>{book.paused_at}</td>
                    <td>
                      {book.end_session === 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#0CE887"
                          class="bi bi-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#E83517"
                          class="bi bi-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberDetail;
