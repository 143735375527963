import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_INTERNAL + '/member';

const initialState = {
  members: [],
  selMember:{},
  selBooking:{
    "id":"",
    "purchase_date": null,
    "movie":{"movie_name":""},
    "ticket_count":0,
    "total_price": 0,
    "percentage_watched":0,
    "paused_at":0,
    "zipcode":"",
    "end_session": 0,
    "booking_payment": []
  },

  isLoading: true
};

export const getMembers = createAsyncThunk(
  'member/getMembers',
  async (tk, thunkAPI) => {
    if (tk === '') {
      console.log('Not Logged In');
      return thunkAPI.rejectWithValue('Not Logged In');
    }
    try {
      const url = baseUrl + '/getMembers';
      console.log(tk);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      let data = { query: 'all' };
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data, {
        headers: { authorization: tk }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getAdminMembers = createAsyncThunk(
  'member/getAdminMembers',
  async (data, thunkAPI) => {
    if (data.token === '') {
      console.log('Not Logged In');
      return thunkAPI.rejectWithValue('Not Logged In');
    }
    try {
      const url = baseUrl + '/getAdminMembers';
      
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const setVerifyEmail = createAsyncThunk(
  'member/setVerifyEmail',
  async (data, thunkAPI) => {
    if (data.token === '') {
      console.log('Not Logged In');
      return thunkAPI.rejectWithValue('Not Logged In');
    }

    try {
      const url = baseUrl + '/setEmailVarified';
      console.log(data.token);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.member, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const getBooking = createAsyncThunk(
  'member/getBooking',
  async (data, thunkAPI) => {
    if (data.token === '') {
      console.log('Not Logged In');
      return thunkAPI.rejectWithValue('Not Logged In');
    }
    try {
      const url = baseUrl + '/getBooking';
      console.log(data.token);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.booking, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const resetBookingPosition = createAsyncThunk(
  'member/resetBookingPosition',
  async (data, thunkAPI) => {
    if (data.token === '') {
      console.log('Not Logged In');
      return thunkAPI.rejectWithValue('Not Logged In');
    }
    try {
      const url = baseUrl + '/resetBookingPosition';
      console.log(data.token);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.booking, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const switchBookingSession = createAsyncThunk(
  'member/switchBookingSession',
  async (data, thunkAPI) => {
    if (data.token === '') {
      console.log('Not Logged In');
      return thunkAPI.rejectWithValue('Not Logged In');
    }
    try {
      const url = baseUrl + '/switchBookingSession';
      console.log(data.token);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.booking, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);


export const toggleStatus = createAsyncThunk(
  'member/toggleStatus',
  async (data, thunkAPI) => {
    if (data.token === '') {
      console.log('Not Logged In');
      return thunkAPI.rejectWithValue('Not Logged In');
    }

    try {
      const url = baseUrl + '/toggleStatus';
      console.log(data.token);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.member, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);

export const saveMemberPersonal = createAsyncThunk(
  'member/saveMemberPersonal',
  async (data, thunkAPI) => {
    if (data.token === '') {
      console.log('Not Logged In');
      return thunkAPI.rejectWithValue('Not Logged In');
    }
    try {
      const url = baseUrl + '/saveMemberPersonal';
      console.log(data.token);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data.member, {
        headers: { authorization: data.token }
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('something went wrong');
    }
  }
);
const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {

    





    updateUpdateMemberPersonal: (state,action) => {
      state.selMember.email = action.payload.email;
      state.selMember.first_name = action.payload.first_name;
      state.selMember.last_name = action.payload.last_name;
      state.selMember.phone = action.payload.phone;
      state.selMember.physical_address = action.payload.physical_address;
      state.selMember.city = action.payload.city;
      state.selMember.state = action.payload.state;
      state.selMember.zipcode = action.payload.zipcode;
      state.selMember.country = action.payload.country;
    },
    updateSelMember: (state,action) => {
      state.selMember = action.payload;

    }
  },
  extraReducers: {
    [toggleStatus.pending]: state => {
      state.isLoading = true;
      
    },
    [toggleStatus.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.selMember = action.payload;
    },
    [toggleStatus.rejected]: (state, action) => {
      console.log(action);
      state.isLoading = false;
    },


    [saveMemberPersonal.pending]: state => {
      state.isLoading = true;
      
    },
    [saveMemberPersonal.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.selMember = action.payload;
    },
    [saveMemberPersonal.rejected]: (state, action) => {
      console.log(action);
      state.isLoading = false;
    },





    [resetBookingPosition.pending]: state => {
      state.isLoading = true;
      
    },
    [resetBookingPosition.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.selBooking = action.payload;
    },
    [resetBookingPosition.rejected]: (state, action) => {
      console.log(action);
      state.isLoading = false;
    },





    [switchBookingSession.pending]: state => {
      state.isLoading = true;
      
    },
    [switchBookingSession.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.selBooking = action.payload;
    },
    [switchBookingSession.rejected]: (state, action) => {
      console.log(action);
      state.isLoading = false;
    },



    [getBooking.pending]: state => {
      state.isLoading = true;
      
    },
    [getBooking.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.selBooking = action.payload;
    },
    [getBooking.rejected]: (state, action) => {
      console.log(action);
      state.isLoading = false;
    },




    [setVerifyEmail.pending]: state => {
      state.isLoading = true;
      
    },
    [setVerifyEmail.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.selMember = action.payload;
    },
    [setVerifyEmail.rejected]: (state, action) => {
      console.log(action);
      state.isLoading = false;
    },


    [getMembers.pending]: state => {
      state.isLoading = true;
      
    },
    [getMembers.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.members = action.payload;
    },
    [getMembers.rejected]: (state, action) => {
      console.log(action);
      state.isLoading = false;
    },

    [getAdminMembers.pending]: state => {
      state.isLoading = true;
      
    },
    [getAdminMembers.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.members = action.payload;
    },
    [getAdminMembers.rejected]: (state, action) => {
      console.log(action);
      state.isLoading = false;
    }
  }
});

// console.log(cartSlice);
export const {updateUpdateMemberPersonal,updateSelMember} = memberSlice.actions;

export default memberSlice.reducer;
