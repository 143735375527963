import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../App.css";


import { updateUser, updateSelUser, getAllUsers, registerUser } from "./userSlice";
import { closeModal,doLogin } from '../modal/modalSlice';

const RegisterUser = () => {
  const { selUser, currentUser } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  

  useEffect(() => {
    const usr = {
        user_id: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
        password: "",
        password1: "",
        user_status: "Pending",
      };
      dispatch(updateSelUser(usr));
  },[]);

  const handleFormChange = ({ currentTarget: input }) => {
    console.log(input.value);

    const usr = {
      user_id: selUser.user_id,
      email: selUser.email,
      first_name: selUser.first_name,
      last_name: selUser.last_name,
      phone: selUser.phone,
      address_1: selUser.address_1,
      address_2: selUser.address_2,
      city: selUser.city,
      state: selUser.state,
      zip: selUser.zip,
      password: selUser.password,
      password1: selUser.password1,
      
    };
    usr[input.name] = input.value;
    //console.log(this.state);
    //this.setState({ login });
    console.log(usr);
    dispatch(updateSelUser(usr));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Before dispatch");

    if(selUser.password === selUser.password1)
        dispatch(registerUser(selUser));
        dispatch(doLogin());

    console.log(selUser);
    
    
  };
  
  return (
    <div className="app-header">
    
      <form onSubmit={handleFormSubmit}>
        <table responsive>
          <tbody>
            <tr>
              <td>Email</td>
              <td>
                <input
                  value={selUser.email}
                  id="email"
                  name="email"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>First Name</td>
              <td>
                <input
                  value={selUser.first_name}
                  id="first_name"
                  name="first_name"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>
                <input
                  value={selUser.last_name}
                  id="last_name"
                  name="last_name"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>
                <input
                  value={selUser.phone}
                  id="phone"
                  name="phone"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                <input
                  value={selUser.address_1}
                  id="address_1"
                  name="address_1"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <input
                  value={selUser.address_2}
                  id="address_2"
                  name="address_2"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>City</td>
              <td>
                <input
                  value={selUser.city}
                  id="city"
                  name="city"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>State</td>
              <td>
                <input
                  value={selUser.state}
                  id="state"
                  name="state"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>Zip</td>
              <td>
                <input
                  value={selUser.zip}
                  id="zip"
                  name="zip"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>Password</td>
              <td>
                <input
                  value={selUser.password}
                  id="password"
                  name="password"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>
              <tr>
              <td>Re-enter Password</td>
              <td>
                <input
                  value={selUser.password1}
                  id="password1"
                  name="password1"
                  onChange={handleFormChange}
                  type="text"
                />
              </td>
            </tr>



            
          </tbody>
        </table>

        <button>Submit</button>
      </form>
    </div>
  );
};

export default RegisterUser;
