// This code displays the home page for the application. It displays the
// banner image if the user is not logged in, or the dashboard if the user
// is logged in.

import React, { useEffect, useState } from "react"; // useEffect, useState
import { useDispatch, useSelector } from "react-redux"; // useDispatch, useSelector
import Header from "../../Header"; // Header
import "../../App.css"; // App.css
import Dashboard from "./dashboard"; // Dashboard

// Banner image
import bannerImage from "../../assets/venuesplash.png";

const Home = () => {
  // currentUser
  const { currentUser } = useSelector((store) => store.user);
  
  // Render
  return (
    <div className='border-white text-white'>
      {/* Header */}
      <Header />

      {/* Dashboard */}
      {currentUser.isLoggedIn ? (
        <Dashboard/>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <img
            src={bannerImage}
            alt="Logged In Banner"
            style={{ width: "90%" }}
          />
        </div>
      )}
    </div>
  );
};
export default Home;