import React from "react";
import { createSlice } from '@reduxjs/toolkit';
import {useSelector} from 'react-redux'
import axios from 'axios';
import { useRoutes } from 'react-router-dom';





const initialState = {
  isModalOpen: false,
  headerText: "",
  modalComponentName: "",
  
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        doLogin: (state) => {
            state.headerText = "Login";
            state.modalComponentName = "Login"
            state.isModalOpen = true;
          },
          doRegisterUser: (state) => {
            state.headerText = "Register User";
            state.modalComponentName = "registerUser"
            state.isModalOpen = true;
          },
          doChangePassword: (state) => {
            state.headerText = "Change Password";
            state.modalComponentName = "changePassword"
            state.isModalOpen = true;
          },
          doAddCompany: (state) => {
            state.headerText = "Add Provider";
            state.modalComponentName = "addCompany"
            state.isModalOpen = true;
          },
        
        closeModal: (state) => {
            state.headerText = "";
            state.modalComponentName = ""
            state.isModalOpen = false;

        },
      
    },
    
  });
  
  // console.log(cartSlice);
  export const {doLogin,closeModal,doRegisterUser,doChangePassword,doAddCompany} =
  modalSlice.actions;
  
  export default modalSlice.reducer;
