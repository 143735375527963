import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../App.css";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";


import Login from "../user/Login";
import RegisterUser from "../user/registerUser";
import ChangePassword from "../user/password";
import Company from "../company/company";
import { closeModal } from "./modalSlice";


const ModalForm = () => {
  const { isModalOpen,headerText,modalComponentName } = useSelector((store) => store.modal);
  const dispatch = useDispatch();
  //let navigate = useNavigate();
  const handleClose = () => {dispatch(closeModal())};
  
  useEffect(() => {
    //if (currentUser.isLoggedIn){
    //  navigate("/")
   // }

  })
    return (
        <Modal show={isModalOpen} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>{headerText}</Modal.Title>
        </Modal.Header>
        {modalComponentName === "Login"?(
        <Modal.Body><Login /></Modal.Body>
        ): ""}

        {modalComponentName === "registerUser"?(
        <Modal.Body><RegisterUser /></Modal.Body>
        ): ""}

      {modalComponentName === "changePassword"?(
        <Modal.Body><ChangePassword /></Modal.Body>
        ): ""}

      {modalComponentName === "addCompany"?(
        <Modal.Body><Company /></Modal.Body>
        ): ""}
      </Modal>
    );
  
};

export default ModalForm;
