import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const baseUrl = process.env.REACT_APP_INTERNAL + "/company";

const initialState = {
  companies: [],
  currentCompany: {
    id: 0,
    name: "",
    campaignActive:0,
    campaignPending:0
  },
  campaigns: [],
  campaignReport: [],
  selCampaign: {
  },
  bookingHistory: [],
  purchaseCountbyHour: [],
  dashboardData: {
    totalCampaigns: 0,
    totalMembers: 0,
    totalBookings: 0,
    totalCompanies: 0,
    totalRevenue: 0
  },

  isLoading: true,
};

export const getDashboardData = createAsyncThunk(
  "company/getDashboardData",
  async (data, thunkAPI) => {
    if (data.token === "") {
      console.log("Not Logged In");
      return thunkAPI.rejectWithValue("Not Logged In");
    }
    try {
      const url = baseUrl + "/getDashboardData";
      //console.log(tk);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,data, {
        headers: { authorization: data.token },
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);





// async thunk that sends a post to removeContentAdmin passing email address.
// This call needs to be authenticated with the token
export const removeContentAdmin = createAsyncThunk(
  "company/removeContentAdmin",
  async (data, thunkAPI) => {
    if (data.token === "") {
      console.log("Not Logged In");
      return thunkAPI.rejectWithValue("Not Logged In");
    }
    try {
      const url = baseUrl + "/removeContentAdmin";
      //console.log(tk);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data, {
        headers: { authorization: data.token },
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);










// async thunk that sends a post to addContentAdmin passing company_id and email address. This call needs
// to be authenticated with the token
export const addContentAdmin = createAsyncThunk(
  "company/addContentAdmin",
  async (data, thunkAPI) => {
    if (data.token === "") {
      console.log("Not Logged In");
      return thunkAPI.rejectWithValue("Not Logged In");
    }
    try {
      const url = baseUrl + "/addContentAdmin";
      //console.log(tk);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url, data, {
        headers: { authorization: data.token },
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);


export const getBookingHistoryByCampaign = createAsyncThunk(
  "company/getBookingHistoryByCampaign",
  async (data, thunkAPI) => {
    if (data.token === "") {
      console.log("Not Logged In");
      return thunkAPI.rejectWithValue("Not Logged In");
    }
    try {
      const url = baseUrl + "/getBookingHistoryByCampaign";
      //console.log(tk);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,data.data, { headers: { authorization: data.token } });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);



export const getPurchaseByHour = createAsyncThunk(
  "company/getPurchaseByHour",
  async (data, thunkAPI) => {
    if (data.token === "") {
      console.log("Not Logged In");
      return thunkAPI.rejectWithValue("Not Logged In");
    }
    try {
      const url = baseUrl + "/getPurchasesByHour";
      //console.log(tk);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,data, { headers: { authorization: data.token } });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);
export const getCampaignReport = createAsyncThunk(
  "company/getCampaignReport",
  async (data, thunkAPI) => {
    if (data.token === "") {
      console.log("Not Logged In");
      return thunkAPI.rejectWithValue("Not Logged In");
    }
    try {
      const url = baseUrl + "/getCampaignReport";
      //console.log(tk);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,data, { headers: { authorization: data.token } });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);
export const getCampaignsByCompany = createAsyncThunk(
  "company/getCampaignsByCompany",
  async (data, thunkAPI) => {
    if (data.token === "") {
      console.log("Not Logged In");
      return thunkAPI.rejectWithValue("Not Logged In");
    }
    try {
      const url = baseUrl + "/getCampaignsByCompany";
      //console.log(tk);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      //thunkAPI.dispatch(openModal());
      const resp = await axios.post(url,data.company, { headers: { authorization: data.token } });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);
export const getAllCompanies = createAsyncThunk(
  "company/getAllCompanies",
  async (tk, thunkAPI) => {
    if (tk === "") {
      console.log("Not Logged In");
      return thunkAPI.rejectWithValue("Not Logged In");
    }
    try {
      const url = baseUrl + "/getAllCompanies";
      //console.log(tk);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
      //thunkAPI.dispatch(openModal());
      const resp = await axios.get(url, { headers: { authorization: tk } });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

export const addCompany = createAsyncThunk(
  "company/addCompany",
  async (dt, thunkAPI) => {
    if (dt.token === "") {
      console.log("Not Logged In");
      return thunkAPI.rejectWithValue("Not Logged In");
    }
    try {
      const url = baseUrl + "/addCompany";
      //console.log(tk);
      console.log(thunkAPI);
      console.log(thunkAPI.getState());
     
      const resp = await axios.post(url, dt.company, {
        headers: { authorization: dt.token },
      });

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("something went wrong");
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setSelCampaign: (state, action) => {
      state.selCampaign = action.payload;
    },
    updateCurrentCompany: (state, action) => {
      state.currentCompany = action.payload;
    },
    
    clearCurrentCompany: (state) => {
        state.currentCompany = initialState.currentCompany;
      },
  },
  extraReducers: {
    [getDashboardData.pending]: (state) => {
      state.isLoading = true;
    },
    [getDashboardData.fulfilled]: (state,action) => {
      // console.log(action);
      state.isLoading = false;
      state.dashboardData = action.payload
    
    },
    [getDashboardData.rejected]: (state) => {
      state.isLoading = false;
    },
    [removeContentAdmin.pending]: (state) => {
      state.isLoading = true;
    },
    [removeContentAdmin.fulfilled]: (state) => {
      // console.log(action);
      state.isLoading = false;
    
    },
    [removeContentAdmin.rejected]: (state) => {
      state.isLoading = false;
    },

    [addContentAdmin.pending]: (state) => {
      state.isLoading = true;
    },
    [addContentAdmin.fulfilled]: (state) => {
      // console.log(action);
      state.isLoading = false;

    },
    [addContentAdmin.rejected]: (state) => {
      state.isLoading = false;

    },


    [getBookingHistoryByCampaign.pending]: (state) => {
      state.isLoading = true;
    },
    [getBookingHistoryByCampaign.fulfilled]: (state,action) => {
      // console.log(action);
      state.isLoading = false;
      state.bookingHistory = action.payload;
      
    },
    [getBookingHistoryByCampaign.rejected]: (state) => {
      state.isLoading = false;
    },




    [getPurchaseByHour.pending]: (state) => {
      state.isLoading = true;
    },
    [getPurchaseByHour.fulfilled]: (state,action) => {
      // console.log(action);
      state.isLoading = false;
      state.purchaseCountbyHour = action.payload;
      
    },
    [getPurchaseByHour.rejected]: (state) => {
      state.isLoading = false;
    },




    [getCampaignReport.pending]: (state) => {
      state.isLoading = true;
    },
    [getCampaignReport.fulfilled]: (state,action) => {
      // console.log(action);
      state.isLoading = false;
      state.campaignReport = action.payload;
      
    },
    [getCampaignReport.rejected]: (state) => {
      state.isLoading = false;
    },
    [getCampaignsByCompany.pending]: (state) => {
      state.isLoading = true;
    },
    [getCampaignsByCompany.fulfilled]: (state,action) => {
      // console.log(action);
      state.isLoading = false;
      state.campaigns = action.payload;
      
    },
    [getCampaignsByCompany.rejected]: (state) => {
      state.isLoading = false;
    },
    [addCompany.pending]: (state) => {
      state.isLoading = true;
    },
    [addCompany.fulfilled]: (state) => {
      // console.log(action);
      state.isLoading = false;
      
    },
    [addCompany.rejected]: (state) => {
      state.isLoading = false;
    },

    [getAllCompanies.pending]: (state) => {
      state.isLoading = true;
      state.companies = [];
    },
    [getAllCompanies.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.companies = action.payload;
    },
    [getAllCompanies.rejected]: (state, action) => {
      console.log(action);
      state.isLoading = false;
    },
  },
});

// console.log(cartSlice);
export const { updateCurrentCompany,clearCurrentCompany,setSelCampaign } = companySlice.actions;

export default companySlice.reducer;
